import { createModel } from '@rematch/core';
import compact from 'lodash/compact';
import orderBy from 'lodash/orderBy';
import sortBy from 'lodash/sortBy';

import type { RootModel } from '..';
import type { StateType as RivalGroupsStateType } from '../rivals/rivalGroups.model';
import type { StateType as RivalsStateType } from '../rivals/rivals.model';
import type { RivalType } from 'api/api.types';
import type { DashboardSortType } from 'api/endpoints/rivals.types';
import type { StoreSelectors } from 'store/store.types';

export const dashboard = createModel<RootModel>()({
  state: null,
  effects: {},
  reducers: {},
  // TODO Remove dashboard model entirely, we should be able to create this
  // selector outside of a model context
  selectors: (_slice, createSelector) => ({
    isLoading(models: StoreSelectors) {
      return createSelector(
        models.rivals.isLoading as any,
        models.rivalGroups.loading as any,
        (isRivalsLoading: boolean, isRivalGroupsLoading: boolean) =>
          isRivalsLoading || isRivalGroupsLoading,
      );
    },
    rivalGroupRivals(models: StoreSelectors) {
      return createSelector(
        models.rivals.byId as any,
        models.rivalGroups.byId as any,
        (
          _state: any,
          {
            rivalGroupId,
            sort,
          }: { rivalGroupId: number | undefined; sort: DashboardSortType },
        ) => ({ rivalGroupId, sort }),
        (
          rivalsById: RivalsStateType['byId'],
          rivalGroupsById: RivalGroupsStateType['byId'],
          { rivalGroupId, sort },
        ) => {
          if (rivalGroupId !== undefined && rivalGroupId >= 0) {
            const rivalGroup = rivalGroupsById.get(rivalGroupId.toString());
            if (rivalGroup) {
              return rivalSorters[sort as DashboardSortType](
                compact(
                  rivalGroup.rivals.map((id) => rivalsById.get(id.toString())),
                ),
              );
            }
          } else {
            return rivalSorters[sort as DashboardSortType]([
              ...rivalsById.values(),
            ]);
          }
        },
      );
    },
  }),
});

function byCards(rivals: RivalType[]) {
  return orderBy(rivals, ['cardsCount', 'name'], ['desc', 'asc']);
}

function byBattlecards(rivals: RivalType[]) {
  return orderBy(
    rivals,
    ['battlecardsCount', 'cardsCount', 'name'],
    ['desc', 'desc', 'asc'],
  );
}

function byCreated(rivals: RivalType[]) {
  return orderBy(rivals, ['createdAt'], ['desc']);
}

function byCreatedReverse(rivals: RivalType[]) {
  return sortBy(rivals, 'createdAt');
}

function byAlpha(rivals: RivalType[]) {
  return sortBy(rivals, [(rival) => rival.name.toLowerCase()]);
}

function byAlphaReverse(rivals: RivalType[]) {
  return orderBy(rivals, [(rival) => rival.name.toLowerCase()], ['desc']);
}

const rivalSorters: {
  [K in DashboardSortType]: (rivals: RivalType[]) => RivalType[];
} = {
  cards: byCards,
  battlecards: byBattlecards,
  created: byCreated,
  'created-rev': byCreatedReverse,
  alpha: byAlpha,
  'alpha-rev': byAlphaReverse,
};
