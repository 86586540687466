import { Dialog, DialogType, Icon } from '@kluein/klue-ui';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import type { Dispatch, RootState } from 'store/store.types';

const PreviewExitInfoDialog = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<Dispatch>();
  const previewExitInfoDialogProps = useSelector(
    (state: RootState) => state.auth.previewExitInfoDialog,
  );

  return (
    <Dialog
      show={!!previewExitInfoDialogProps}
      onClose={() => dispatch.auth.setPreviewExitInfoDialog(null)}
      title={t('Common:notification.previewing.previewExitInfo.dialog.title')}
      description={t(
        'Common:notification.previewing.previewExitInfo.dialog.desc',
        { destination: previewExitInfoDialogProps?.destination },
      )}
      actionLabel={t('Common:actions.confirm')}
      action={() => {
        previewExitInfoDialogProps?.redirectUrl &&
          (window.location.href = previewExitInfoDialogProps?.redirectUrl);
      }}
      icon={<Icon.Info />}
      type={DialogType.INFO}
      position="center"
    />
  );
};
export default PreviewExitInfoDialog;
