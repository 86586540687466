import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';

import { getCsrfToken } from 'api/interceptors/userInterceptor';
import { API_BASEURL } from 'lib/urls';
import store from 'store';
import 'store/configureRTK';

export const api = createApi({
  reducerPath: 'api',
  baseQuery: retry(
    fetchBaseQuery({
      baseUrl: API_BASEURL,
      prepareHeaders: (headers) => {
        headers.set('X-CSRF-Token', getCsrfToken());
        return headers;
      },
    }),
    {
      retryCondition: () => false, // disable retries by default
    },
  ),
  tagTypes: [
    'Files',
    'Token',
    'Notes',
    'Websites',
    'Battlecards',
    'Products',
    'Project',
    'Projects',
    'ComposerCards',
    'Sources',
    'UseCases',
    'Overviews',
    'CompanyProfileExtractProducts',
    'CompanyProfileExtractUseCases',
  ],
  endpoints: () => ({}), // endpoints are injected for code-splitting
});

const apiModel = {
  name: 'api',
  state: {},
  reducers: {},
  baseReducer: api.reducer,
};

store.addMiddleware(api.middleware);
store.addModel(apiModel);
