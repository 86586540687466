import {
  Anchor,
  Box,
  Text,
  Image,
  Logo,
  Breadcrumbs,
  tokens,
} from '@kluein/klue-ui';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { useAuth } from 'contexts/auth';
import { useRedirect } from 'hooks/use-redirect';
import { APP_V1_BASEURL } from 'lib/urls';
import { startCase, formatName } from 'lib/utils';
import TEST_IDS from 'test-ids';
import TRACKING_IDS from 'tracking-ids';

import type { SingleCardViewerProps } from '../single-card-viewer/SingleCardViewer.types';
import type { RivalType, UserType } from 'api/api.types';
import type { RootState } from 'store/store.types';

function SingleCardViewerFooter(
  props: Pick<
    SingleCardViewerProps,
    'profile' | 'card' | 'onProfileClick' | 'onLaneClick'
  >,
) {
  const { profile, card, onProfileClick, onLaneClick } = props;
  const { t } = useTranslation();
  const { user, showCardInfo } = useAuth();
  const boardName = startCase(card?.board.name);
  const userAuthor = useSelector<RootState, UserType | undefined>((state) =>
    state.users.byId.get(String(card?.reviewer?.id || card?.author?.id)),
  );

  const currentProfileRival = useSelector<RootState, RivalType | undefined>(
    (state) => {
      return state.rivals.byId.get((profile?.rivalId || '').toString());
    },
  );

  const getDateFormated = () => {
    if (!card?.reviewer?.reviewedAt && !card?.updatedAt) return '';

    return format(
      new Date(card?.reviewer?.reviewedAt || card.updatedAt),
      'LLL dd, yyyy',
    ).toLocaleUpperCase();
  };

  const userName = () => {
    return user?.id === card?.reviewer?.id || user?.id === card?.author?.id
      ? t('Common:you')
      : userAuthor?.name || card?.author?.name;
  };

  const { redirectToProfile } = useRedirect();

  return (
    <Box
      direction="row"
      style={{ zIndex: 1 }}
      gap="medium"
      align="center"
      justify="between"
      pad="large"
      flex={{ shrink: 0 }}
      border={{
        side: 'top',
        size: 'xsmall',
        color: tokens.color.neutral.lightgrey.main,
      }}
      data-test-id={TEST_IDS.cardViewer.breadcrumb}
    >
      {!!profile?.id && (
        <Box
          direction="row"
          gap="medium"
          align="center"
          margin={{ right: 'small' }}
        >
          <Logo
            a11yTitle={profile.name}
            src={currentProfileRival?.iconUrl}
            onClick={() => redirectToProfile(profile.id)}
            border
          />
          <Breadcrumbs
            multiline
            items={[
              {
                title: profile.name,
                onClick: onProfileClick,
                'data-tracking-id':
                  TRACKING_IDS.global.cardViewer.breadcrumbs.board,
              },
              {
                title: boardName,
                onClick: onLaneClick,
                'data-tracking-id':
                  TRACKING_IDS.global.cardViewer.breadcrumbs.lane,
              },
            ]}
          />
        </Box>
      )}
      {!!userName() && showCardInfo && (
        <Box direction="row" align="center" gap="small">
          <Text
            as="time"
            size="small"
            color={tokens.color.neutral.black.main}
            fontWeight="semibold"
            className="chromatic-ignore"
            letterSpacing="medium"
            textAlign="end"
          >
            {getDateFormated()}
          </Text>
          <Box
            width="hair"
            height="xxsmall"
            background={tokens.color.neutral.mediumgrey.main}
          />
          {userAuthor?.image && (
            <Box
              border={{
                color: tokens.color.neutral.black.main,
                side: 'all',
                size: 'small',
              }}
              overflow="hidden"
              round="full"
              width="xxsmall"
              height="xxsmall"
            >
              <Image alt={userName()} src={userAuthor?.image} fit="contain" />
            </Box>
          )}
          <Text
            size="small"
            color={tokens.color.neutral.black.main}
            fontWeight="semibold"
          >
            <Anchor
              href={`${APP_V1_BASEURL}/users/${card?.author?.id}`}
              label={formatName(userName() || '')}
              data-tracking-id={
                TRACKING_IDS.global.cardViewer.breadcrumbs.author
              }
            />
          </Text>
        </Box>
      )}
    </Box>
  );
}

export default SingleCardViewerFooter;
