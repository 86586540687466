import React from 'react';

import type { AuthContextType } from './auth.types';

const initialValue = {
  loading: true,
  user: null,
  company: null,
  signOut: () => null,
  updateUser: () => null,
  fetchUser: () => null,
  homeRoute: '/dashboard',
  showCardInfo: true,
  isReportsEnabled: false,
  isTagVisible: false,
  isCurator: false,
  isAdmin: false,
  isStaff: false,
  isDigestAvailable: false,
  isImpersonating: false,
  revertImpersonatingUser: () => null,
  isCardViewerUpdateEnabled: false,
  isDashboardNewsEnabled: false,
  isCardFeedbackDisabled: true,
  isCardSentimentEnabled: false,
  isCardSentimentAndFeedbackOnHoverDisabled: false,
  isAlertsRecommendedTagEnabled: false,
  isAlertsAutoSummarizeOnTriageEnabled: true,
  isWinLossEnabled: false,
  isWinLossExclusive: false,
  isBattlecardLayoutLabelDisabled: false,
  isCompetitiveAnalyticsOverviewReportEnabled: false,
  isCompetitiveAnalyticsThreatAnalysisReportEnabled: false,
  isCompetitiveAnalyticsRepPerformanceReportEnabled: false,
  isCompetitiveAnalyticsDataHygieneReportEnabled: false,
  isCompetitiveAnalyticsImpactReportEnabled: false,
  isCompetitiveAnalyticsProgramImpactReportEnabled: false,
  isConsumerReportEnabledForCurators: false,
  isCuratorReportEnabledForCurators: false,
  isCRAReportEnabledForCurators: false,
  isCompetitiveDashboardEnabledForConsumers: false,
  isDashboardAskKlueEnabled: false,
  isDashboardNewsToggleDefaultOff: false,
  isSearchResultsAskKlueEnabled: false,
  isCompanyOverviewEnabled: false,
  isCompanyFavoritesEnabled: false,
  isGAEnabled: false,
  isSFDCDataSyncContactsEnabled: false,
  isSFDCDataSyncUserRoleEnabled: false,
  isIntegrationServiceEnabled: false,
  isReviewInsightsCreateCardEnabled: false,
  isProfileSideNavNewsDisabled: false,
  isResearchEnabled: false,
  isComposerEnabled: false,
  isResearchDevEnabled: false,
  isResearchChatEnabled: false,
  isAlertsTitleEditingDisabled: false,
  isNewCuratorNavEnabled: false,
  isFeedHiddenInConsumerNavEnabled: false,
  isInsightsWhyWeWinLoseEnabled: false,
  isCallInsightsDemoReplacementEnabled: false,
  isNewCardEditorEnabled: false,
  isV2CuratorDashboardEnabled: false,
  isSearchSuggestionBattlecardsEnabled: false,
  isGenAISearchResultsEnabled: false,
  isChatEnabledForAskKlue: false,
  isSFDSCustomLookupObjectEnabled: false,
};

const authContext = React.createContext<AuthContextType>(initialValue);

export default authContext;
