import { createModel } from '@rematch/core';

import { fetchCardById } from 'api/endpoints/card';
import { deleteBoards, fetchProfileRival } from 'api/endpoints/profile';
import { bulkUpdateCardTags } from 'api/endpoints/tags';
import {
  addVisibilityGroupIdToQuery,
  mergeMapValues,
  populateEntitiesFrom,
} from 'store/utils';
import { parseCardContent, parseCardsContent } from 'worker';

import type { CardType } from 'api/api.types';
import type { FetchCardByIdQueryType } from 'api/endpoints/card.types';
import type { FetchProfileRivalQueryType } from 'api/endpoints/profile.types';
import type { RootModel } from 'store/models';

export type StateType = {
  byId: Map<string, CardType>;
  allIds: Set<string>;
  selectedCards: Set<number>;
};

export const initialState = {
  byId: new Map(),
  allIds: new Set(),
  selectedCards: new Set(),
} as StateType;

type PopulatePayload = { cards: Record<number, CardType> };
type ToggleSelectedPayload = { id: number };
type SelectPayload = { cardIds: Array<number>; selectAll: boolean };
type DeletePayload = { cardIds: Array<number> };
type UpdateCardsMetadataPayload = {
  updatedCards: Array<Partial<CardType>>;
};
type UpdateCardsAndPopulatePayload = {
  updatedCards: Array<CardType>;
};

export const cards = createModel<RootModel>()({
  state: initialState,
  reducers: {
    addNewCard: (state: StateType, card: CardType) => {
      return {
        ...state,
        byId: new Map([...state.byId, [card.id.toString(), card]]),
        allIds: new Set([...state.allIds, card.id.toString()]),
      };
    },
    populate: (state: StateType, { cards }: PopulatePayload) => {
      return {
        ...state,
        byId: mergeMapValues(state.byId, cards),
        allIds: new Set([...state.allIds, ...Object.keys(cards)]),
      };
    },
    delete: (state: StateType, { cardIds }: DeletePayload) => {
      return {
        ...state,
        byId: new Map(
          [...state.byId].filter(
            (entry) => !cardIds.includes(parseInt(entry[0], 10)),
          ),
        ),
        allIds: new Set(
          [...state.allIds].filter((id) => !cardIds.includes(parseInt(id, 10))),
        ),
      };
    },
    toggleSelectedCard: (state: StateType, { id }: ToggleSelectedPayload) => {
      const updatedSelectedCards = new Set<number>(state.selectedCards);
      if (updatedSelectedCards.has(id)) {
        updatedSelectedCards.delete(id);
      } else {
        updatedSelectedCards.add(id);
      }
      return {
        ...state,
        selectedCards: updatedSelectedCards,
      };
    },
    selectCards: (
      state: StateType,
      { cardIds, selectAll = true }: SelectPayload,
    ) => {
      const updatedSelectedCards = new Set<number>(state.selectedCards);
      cardIds.forEach((id) => {
        if (selectAll) {
          return updatedSelectedCards.add(id);
        }
        updatedSelectedCards.delete(id);
      });
      return {
        ...state,
        selectedCards: updatedSelectedCards,
      };
    },
    selectAllCards: (state: StateType, selectedIds) => {
      return {
        ...state,
        selectedCards: new Set<number>(
          [...selectedIds].map((id) => parseInt(id, 10)),
        ),
      };
    },
    deselectAllCards: (state: StateType) => {
      return {
        ...state,
        selectedCards: new Set([]),
      };
    },
    updateCardsMetadata: (
      state: StateType,
      { updatedCards }: UpdateCardsMetadataPayload,
    ) => {
      const updatedById = new Map(state.byId);
      updatedCards.forEach((card) => {
        const idAsString = card.id?.toString();
        if (!idAsString) {
          return;
        }

        const currentCard = updatedById.get(idAsString);
        if (currentCard) {
          updatedById.set(idAsString, Object.assign({}, currentCard, card));
        }
      });

      return {
        ...state,
        byId: updatedById,
      };
    },
    batchUpdateCardTags: (state: StateType, cards: Array<CardType>) => {
      const newMap = new Map(state.byId);
      cards.forEach((nCard) => {
        const current = state.byId.get(nCard.id.toString()) as CardType;

        if (!!current) {
          newMap.set(nCard.id.toString(), {
            ...current,
            tags: nCard.tags,
          });
        }
      });

      return {
        ...state,
        byId: newMap,
      };
    },
  },
  selectors: (slice) => ({
    byId() {
      return slice(({ byId }) => byId);
    },
    selectedCards() {
      return slice(({ selectedCards }) => selectedCards);
    },
  }),
  effects: (dispatch) => ({
    async loadOrFetchCardById(id: number, rootState): Promise<CardType | null> {
      // first check if card is present in global state
      const existingCard = rootState.cards.byId.get(id.toString());
      if (existingCard?.id) {
        return existingCard;
      }

      const query: FetchCardByIdQueryType = { v: '2' };
      addVisibilityGroupIdToQuery(rootState, query);

      try {
        const { data } = await fetchCardById({
          path: {
            id,
          },
          query,
        });

        if (!data?.id) {
          return null;
        }

        const parsedCard = parseCardContent(data);

        dispatch.cards.populate({ cards: { [parsedCard.id]: parsedCard } });

        // load also profile data if it not exist
        const currentProfile = rootState.profiles.byId.get(
          String(parsedCard.board.profileId),
        );

        if (!currentProfile?.id && parsedCard?.board?.profileId) {
          const query: FetchProfileRivalQueryType = {};
          addVisibilityGroupIdToQuery(rootState, query);
          const { data: rivalData } = await fetchProfileRival({
            path: { id: parsedCard.board.profileId },
            query,
          });

          populateEntitiesFrom({
            entities: rivalData.entities,
            dispatch,
          });
        }

        return parsedCard;
      } catch (error) {
        return null;
      }
    },
    // eslint-disable-next-line require-await
    async addAndParseNewCard({
      newCard,
      boardCards,
    }): Promise<CardType | null> {
      try {
        const parsedCard = parseCardContent(newCard);

        dispatch.cards.addNewCard(parsedCard);

        dispatch.boards.addCardToBoard({
          boardId: parsedCard.board.id,
          card: parsedCard,
          cards: boardCards,
        });

        return parsedCard;
      } catch (error) {
        return null;
      }
    },
    // eslint-disable-next-line require-await
    async updateAndParseCardsData({
      updatedCards,
    }: UpdateCardsAndPopulatePayload): Promise<Record<
      number,
      CardType
    > | null> {
      const map: { [key: number]: CardType } = {};
      updatedCards.forEach((card) => (map[card.id] = card));
      const parsedCards = parseCardsContent(map);
      const cards = parsedCards;
      dispatch.cards.populate({ cards });

      return cards;
    },

    async bulkAddTags(_, rootState) {
      const { cards, tags } = rootState;

      try {
        const { data } = await bulkUpdateCardTags({
          params: {
            cardIds: Array.from(cards.selectedCards),
            addTags: Array.from(tags.selectedTags),
            keepUpdatedAt: true,
          },
        });

        dispatch.cards.batchUpdateCardTags(data);

        return true;
      } catch (error) {
        return false;
      }
    },

    async bulkRemoveTags(_, rootState) {
      const { cards, tags } = rootState;

      try {
        const { data } = await bulkUpdateCardTags({
          params: {
            cardIds: Array.from(cards.selectedCards),
            removeTags: Array.from(tags.selectedTags),
            keepUpdatedAt: true,
          },
        });

        dispatch.cards.batchUpdateCardTags(data);

        return true;
      } catch (error) {
        return false;
      }
    },

    bulkDeleteCards(_, rootState) {
      const { cards } = rootState;
      const { selectedCards } = cards;
      const cardIds = Array.from(selectedCards);
      const selectedBoardIds: number[] = cardIds.reduce((acc, curr) => {
        const card = rootState.cards.byId.get(curr.toString());
        const boardId = card?.board.id;
        return [...acc].includes(boardId) ? acc : [...acc, boardId];
      }, [] as any);

      const boardIdsToDelete = selectedBoardIds.reduce((acc, curr) => {
        const board = rootState.boards.byId.get(curr.toString());
        const filteredCards = board?.cards?.filter(
          (card) => !selectedCards.has(card.id),
        );
        if (filteredCards?.length === 0) {
          return [...acc, curr];
        }
        return acc;
      }, [] as any);

      dispatch.boards.deleteCards({ cardIds });
      dispatch.cards.delete({ cardIds });

      if (boardIdsToDelete.length > 0) {
        deleteBoards({
          params: { boardIds: boardIdsToDelete },
        })
          .then(({ status }) => {
            if (status === 200) {
              dispatch.profiles.deleteBoards({ boardIds: boardIdsToDelete });
              dispatch.boards.delete({ boardIds: boardIdsToDelete });
            }
          })
          .catch(() => {
            throw new Error(`Failed to delete boards: ${boardIdsToDelete}`);
          });
      }
    },
  }),
});
