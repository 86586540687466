import {
  Box,
  Text,
  Anchor,
  Icon,
  Button,
  Image,
  tokens,
} from '@kluein/klue-ui';
import { format } from 'date-fns';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { useAuth } from 'contexts/auth';
import { APP_V1_BASEURL } from 'lib/urls';
import { formatName } from 'lib/utils';
import TEST_IDS from 'test-ids';
import TRACKING_IDS from 'tracking-ids';

import type { KlueCardInfoPropsType } from './KlueCardInfo.types';
import type { UserType } from 'api/api.types';
import type { Dispatch, RootState } from 'store/store.types';

const IconButton = styled(Button)`
  height: 20px;
  display: flex;
  align-items: center;
`;

const KlueCardInfo = ({
  author,
  updatedAt,
  reviewer,
  type,
  sourcesCount,
  hasCardSourcesIcon = true,
  onCardSourcesClick,
  shouldOpenInNewTab = false,
}: KlueCardInfoPropsType) => {
  const { t } = useTranslation();
  const { user, isCardViewerUpdateEnabled } = useAuth();
  const dispatch = useDispatch<Dispatch>();
  const userAuthor = useSelector<RootState, UserType | undefined>((state) =>
    state.users.byId.get(String(reviewer?.id || author?.id)),
  );

  const userName = () => {
    return user?.id === reviewer?.id || user?.id === author?.id
      ? t('Common:you')
      : userAuthor?.name || author?.name;
  };
  const dateString = reviewer?.reviewedAt || updatedAt;
  const updateDateFormated = dateString
    ? format(new Date(dateString), 'LLL dd, yyyy').toLocaleUpperCase()
    : '';

  const hasCardSources = !!sourcesCount;
  const shouldShowCardSourcesIcon =
    hasCardSourcesIcon && hasCardSources && !isCardViewerUpdateEnabled;

  const sourcesTooltipContent = hasCardSources
    ? t('Card:sources.showSourcesTooltip', { count: sourcesCount })
    : t('Card:sources.addSources');

  useEffect(() => {
    if (author?.id) {
      dispatch.users.loadOrFetchUserById(author.id);
    }
  }, [dispatch, author?.id]);

  return (
    <Box
      direction="row"
      fill
      align="center"
      margin="none"
      width="auto"
      flex="grow"
      justify="between"
      data-test-id={TEST_IDS.klueCard.klueCardInfo.container}
    >
      {!!userName() && (
        <Box direction="row" align="center" gap="small">
          <Text
            as="time"
            size="small"
            color={tokens.color.neutral.black.main}
            fontWeight="semibold"
            className="chromatic-ignore"
          >
            {updateDateFormated}
          </Text>
          <Box
            width="hair"
            height="xxsmall"
            background={tokens.color.neutral.mediumgrey.main}
          />
          {userAuthor?.image && (
            <Box
              border={{
                color: tokens.color.neutral.black.main,
                side: 'all',
                size: 'small',
              }}
              overflow="hidden"
              round="full"
              width="xxsmall"
              height="xxsmall"
            >
              <Image alt={userName()} src={userAuthor?.image} fit="contain" />
            </Box>
          )}
          <Text
            size="small"
            color={tokens.color.neutral.black.main}
            fontWeight="semibold"
          >
            <Anchor
              href={`${APP_V1_BASEURL}/users/${userAuthor?.id}`}
              label={formatName(userName() || '')}
              data-tracking-id={TRACKING_IDS.global.card.author}
              external={shouldOpenInNewTab}
              hideExternalIcon
            />
          </Text>
        </Box>
      )}
      {!!shouldShowCardSourcesIcon && (
        <Box align="end">
          <IconButton
            variant="plain"
            onClick={onCardSourcesClick}
            title={t('Card:sources.title')}
            size="small"
            tip={{
              content: sourcesTooltipContent.toUpperCase(),
              dropProps: { align: { bottom: 'top' } },
            }}
            data-test-id={TEST_IDS.klueCard.klueCardInfo.cardSourceIcon}
            icon={
              <Icon.CircleInformation
                color={tokens.color.neutral.black.main}
                size="small"
              />
            }
          />
        </Box>
      )}
    </Box>
  );
};

export default KlueCardInfo;
