import { Box, Button, Icon, Text, tokens } from '@kluein/klue-ui';
import { useState, useRef, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import useOnOutsideClick from 'hooks/use-on-outside-click/useOnOutsideClick';
import useVisibilityGroups from 'hooks/use-visibility-groups/useVisibilityGroups';
import { CardPermissions } from 'pages/profile/partials/sideAction/bulkEdit';
import TEST_IDS from 'test-ids';
import TRACKING_IDS from 'tracking-ids';

import {
  VISIBILITY_GROUPS_COLORS,
  getVisibilityGroupUIElements,
  getVisibleToValue,
  getVisibilityGroupColors,
} from './helpers';

import type { KlueCardVisibilityGroupsProps } from './KlueCardVisibilityGroups.types';
import type { GetCardPermissionCardsDataResult } from 'pages/profile/partials/sideAction/bulkEdit/cardPermissions/CardPermissions.utils';
const KlueCardVisibilityGroups = ({
  cardId,
  size = 'small',
  round = false,
  topBorderOnly = false,
  margin = 'none',
  flex = 'shrink',
  multiline = false,
  isDraft = false,
  allAccess = false,
  visibilityGroups = [],
  children,
  zIndex = 0,
  onVisibilityGroupSelected,
}: KlueCardVisibilityGroupsProps) => {
  const {
    selectedGroups,
    visibleTo,
    visibilityGroups: allVisibilityGroups,
    setSelectGroups,
    setVisibleTo,
    getCardPermissionCardsData,
  } = useVisibilityGroups();
  const [showVisibilityGroups, setShowVisibilityGroups] = useState(false);
  const [visibilityBackgroundColor, setVisibilityBackgroundColor] =
    useState<string>(VISIBILITY_GROUPS_COLORS.curatorsOnly.background);
  const { t } = useTranslation(['Card']);
  const ref = useRef<HTMLDivElement>(null);
  useOnOutsideClick({
    ref,
    onOutsideClick: () => {
      setShowVisibilityGroups(false);
    },
  });

  const visibilityGroupsSorted = (visibilityGroups || []).sort(
    ({ name: nameA }: { name: string }, { name: nameB }: { name: string }) => {
      if (nameA === nameB) {
        return 0;
      }
      return nameA < nameB ? -1 : 1;
    },
  );
  const {
    text,
    background,
    border,
    color,
    icon: IconComp,
    size: iconSize,
  } = useMemo(
    () =>
      getVisibilityGroupUIElements({
        isDraft,
        allAccess,
        visibilityGroups: visibilityGroupsSorted,
      }),
    [isDraft, allAccess, visibilityGroupsSorted],
  );

  const handleVisibilityGroupSelected = () => {
    if (onVisibilityGroupSelected) {
      if (!showVisibilityGroups) {
        setSelectGroups(visibilityGroups.map(({ id }) => id));
      }
      setShowVisibilityGroups(!showVisibilityGroups);
    }
  };

  const handleDoneVisibilityGroupSelected = () => {
    setShowVisibilityGroups(false);
    if (onVisibilityGroupSelected) {
      const cardPermissionCardsData: GetCardPermissionCardsDataResult =
        getCardPermissionCardsData({
          visibleTo,
          selectedGroups,
          selectedCards: new Set([cardId ?? 0]),
          visibilityGroups: allVisibilityGroups,
        });

      onVisibilityGroupSelected(cardPermissionCardsData);
    }
  };

  useEffect(() => {
    const visibleTo = getVisibleToValue({
      isDraft,
      allAccess,
      visibilityGroups,
    });
    const color = getVisibilityGroupColors({
      isDraft,
      allAccess,
      visibilityGroups,
    });
    setVisibleTo(visibleTo);
    setVisibilityBackgroundColor(color.background);
  }, [
    isDraft,
    allAccess,
    visibilityGroups,
    setVisibleTo,
    setVisibilityBackgroundColor,
  ]);

  if (!text.length) {
    return null;
  }

  return (
    <Box
      direction="row"
      align="center"
      margin={margin}
      round={round ? 'xxsmall' : undefined}
      border={{ side: topBorderOnly ? 'top' : 'all', color: border }}
      background={background}
      flex={flex}
      data-test-id={TEST_IDS.klueCard.visibilityGroups.container}
      pad={{ horizontal: 'xsmall', vertical: 'hair' }}
      style={{ position: 'relative', zIndex }}
      ref={ref}
    >
      {showVisibilityGroups && (
        <Box
          background={tokens.color.neutral.white.main}
          // setting maxWidth to 300px here since setting it in Box
          // seems to not work with absolute positioning (in some cases - e.g. disabled checkboxes)
          style={{ position: 'absolute', bottom: 0, maxWidth: '300px' }}
          elevation="xlarge"
          height={{ max: '500px' }}
        >
          <Box pad={{ horizontal: 'small', top: 'small' }}>
            <CardPermissions visibleToDefault={visibleTo} showTitle={false} />
          </Box>
          <Box
            pad={{ vertical: 'xxsmall', horizontal: 'xxsmall' }}
            background={visibilityBackgroundColor}
            justify="end"
            align="end"
            focusIndicator={false}
            flex={{ shrink: 0 }}
          >
            <Button
              size="small"
              variant="flat-outlined-form"
              label={t('Card:editor.visibilityGroups.done')}
              onClick={handleDoneVisibilityGroupSelected}
              data-test-id={TEST_IDS.klueCard.visibilityGroups.done}
              data-tracking-id={TRACKING_IDS.editor.visibilityGroups.done}
            />
          </Box>
        </Box>
      )}
      <Box
        direction="row"
        width="100%"
        align="center"
        onClick={
          onVisibilityGroupSelected ? handleVisibilityGroupSelected : undefined
        }
        focusIndicator={!onVisibilityGroupSelected}
      >
        <IconComp size={iconSize} />
        <Text
          color={color}
          margin={{ left: 'xsmall' }}
          size={size}
          truncate={true}
          data-test-id={TEST_IDS.klueCard.visibilityGroups.text}
        >
          {t(text)}
        </Text>
        {onVisibilityGroupSelected && <Icon.ChevronDownSmall />}
      </Box>
      {children ? (
        <Box
          flex={{ shrink: 0 }}
          direction="row"
          pad={{ vertical: 'small', horizontal: 'small' }}
          justify="end"
        >
          {children}
        </Box>
      ) : null}
    </Box>
  );
};

export default KlueCardVisibilityGroups;
