import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import {
  type AnalyticsActionType,
  AnalyticsCategory,
  useAnalytics,
} from 'lib/analytics';
import store from 'store';

type ViewType = 'search' | 'battlecard' | 'board' | 'embed' | null;
type CommonCardInteractionType = {
  action: AnalyticsActionType;
  label: string | null;
};

type KlueCardInteractionType = {
  cardId: number;
  rank?: number | null;
};

type BoardCardInteractionType = {
  rivalId: number;
  battlecardId?: number;
};

export const calculateView = (
  path: string = window.location.pathname,
): ViewType => {
  if (path.startsWith('/search')) return 'search';
  if (path.includes('/battlecard')) return 'battlecard';
  if (path.startsWith('/card/embed')) return 'embed';
  return 'board';
};

export function useBoardCardInteractionLog() {
  const { logEvent } = useAnalytics();

  return useCallback(
    ({
      action,
      label,
      rivalId,
      battlecardId,
    }: CommonCardInteractionType & BoardCardInteractionType) => {
      const data: Record<string, any> = {
        entities: ['board'],
        cardType: 'board_card',
        view: 'search',
        label,
        board: { id: rivalId, idType: 'rival' },
      };

      if (battlecardId) {
        data.battlecard = { id: battlecardId };
        data.entities.push('battlecard');
      }

      logEvent({
        event: {
          provider: 'sp',
          category: AnalyticsCategory.cardInteraction,
          action,
        },
        data,
      });
    },
    [logEvent],
  );
}

export default function useCardInteractionLog() {
  const { logEvent } = useAnalytics();
  const currentRivalId = useSelector(store.select.profiles.currentRivalId);
  const currentBattlecardId = useSelector(
    store.select.battlecards.currentBattlecardId,
  );
  return useCallback(
    ({
      cardId,
      action,
      label,
      rank,
    }: CommonCardInteractionType & KlueCardInteractionType) => {
      const data: Record<string, any> = {
        entities: ['card'],
        cardType: 'klue_card',
        view: calculateView(),
        label,
        card: { id: cardId, rank },
      };

      if (data.view === 'board') {
        data.entities.push('board');
        data.board = { id: currentRivalId, idType: 'rival' };
      }

      if (data.view === 'battlecard') {
        data.entities.push('battlecard');
        data.battlecard = { id: currentBattlecardId };
      }

      logEvent({
        event: {
          provider: 'sp',
          category: AnalyticsCategory.cardInteraction,
          action,
        },
        data,
      });
    },
    [currentBattlecardId, currentRivalId, logEvent],
  );
}
