import merge from 'lodash/merge';
import { useEffect, useReducer } from 'react';
import { Provider } from 'react-redux';
import * as redux from 'react-redux';

import type { Models, Plugin, RematchStore } from '@rematch/core';
import type { Store } from 'redux';
import type { RootState, Dispatch } from 'store/store.types';
import type { Mock } from 'vitest';

const MERGE_TYPE = '__TEST_MERGE';
const RESET_TYPE = '__TEST_RESET';

let initialState: RootState;
export const testPlugin = <
  TModels extends Models<TModels>,
  TExtraModels extends Models<TModels> = Record<string, never>,
>(): Plugin<TModels, TExtraModels> => ({
  onRootReducer(rootReducer) {
    return (state, action) => {
      if (action.type === RESET_TYPE) {
        return action.payload;
      }
      if (action.type === MERGE_TYPE) {
        return merge({}, state, ...action.payload);
      }
      return rootReducer(state, action);
    };
  },
  onStoreCreated(store: RematchStore<TModels, TExtraModels>) {
    initialState = store.getState() as RootState;
    if (import.meta.env.VITEST_WORKER_ID !== undefined) {
      setupMockDispatch(store.dispatch as Dispatch);
    }
    resetState = () =>
      store.dispatch({ type: RESET_TYPE, payload: initialState });
    mergeState = (state) =>
      store.dispatch({ type: MERGE_TYPE, payload: state });
  },
});

let mergeState: (state: RootState[]) => void;
let resetState: () => void;

type TestProviderProps = {
  children: React.ReactNode;
  store: Store;
  initialStateOverrides?: RootState | RootState[];
};
export const TestProvider = ({
  children,
  store,
  initialStateOverrides,
}: TestProviderProps) => {
  const [isReady, setReady] = useReducer(() => true, !initialStateOverrides);
  useEffect(() => {
    if (initialStateOverrides) {
      resetState();
      mergeState(
        Array.isArray(initialStateOverrides)
          ? initialStateOverrides
          : [initialStateOverrides],
      );
      setReady();
    }
  }, [initialStateOverrides]);

  return <Provider store={store}>{isReady && children}</Provider>;
};

export const mockUseSelector = () => {
  const store = {
    state: initialState,
  };
  beforeEach(() =>
    vi
      .spyOn(redux, 'useSelector')
      .mockImplementation((selector: (state: RootState) => unknown) => {
        if (typeof selector !== 'function') return selector; // TODO Figure out why this is needed
        return selector(store.state);
      }),
  );
  return {
    mergeState: (state: RootState) => {
      store.state = merge({}, store.state, state);
    },
    replaceState: (state: RootState) => {
      store.state = state;
    },
    resetState: () => {
      store.state = initialState;
    },
  };
};

type MockDispatch = {
  [Model in keyof Dispatch]: {
    [Effect in keyof Dispatch[Model]]: Mock; // TODO Improve type
  };
};

const mockDispatch = {} as MockDispatch;

function setupMockDispatch(dispatch: Dispatch) {
  for (const model of Object.keys(dispatch)) {
    mockDispatch[model] = {};
    for (const effect of Object.keys(dispatch[model])) {
      mockDispatch[model][effect] = vi.fn();
    }
  }
}

export const mockUseDispatch = () => {
  beforeEach(() =>
    vi.spyOn(redux, 'useDispatch').mockImplementation(() => {
      return mockDispatch as unknown as Dispatch;
    }),
  );
  return {
    dispatch: mockDispatch,
  };
};

export const mockReduxHooks = () => ({
  ...mockUseDispatch(),
  ...mockUseSelector(),
});
