import {
  Box,
  Button,
  Heading,
  Icon,
  Image,
  Modal,
  Text,
  theme,
  TextArea,
  Banner,
  Breadcrumbs,
  Logo,
  tokens,
} from '@kluein/klue-ui';
import startCase from 'lodash/startCase';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import SUBMIT_INTEL_IMG from 'assets/submit-intel.svg?inline';
import store from 'store';
import { useCreateCardCommentMutation } from 'store/api/comments';
import TEST_IDS from 'test-ids';
import TRACKING_IDS from 'tracking-ids';

import type { ProfileType, RivalType } from 'api/api.types';
import type { RootState } from 'store/store.types';

type KlueCardFeedbackModalProps = {
  onClickModal: (param: boolean) => void;
  isFeedbackModalOpen: boolean;
  cardId: number;
  cardName?: string | undefined | null;
  profileId?: number | undefined | null;
  rivalId?: number;
};

type BreadcrumbProps = {
  profileId: number;
  cardName: string;
  rivalId?: number;
};

const FEEDBACK_INTERVAL = 5000;

function Breadcrumb({ profileId, rivalId, cardName }: BreadcrumbProps) {
  const profile = useSelector<RootState, ProfileType | undefined>((state) =>
    store.select.profiles.byId(state).get(profileId.toString()),
  );

  const rival = useSelector<RootState, RivalType | undefined>((state) =>
    store.select.rivals
      .byId(state)
      .get(profile?.rivalId.toString() || rivalId?.toString() || ''),
  );

  return (
    <Box direction="row" gap="small" align="center">
      <Logo a11yTitle={rival?.name} src={rival?.iconUrl} border />
      <Breadcrumbs
        multiline
        items={[{ title: rival?.name || '' }, { title: startCase(cardName) }]}
      />
    </Box>
  );
}

function KlueCardFeedbackModal({
  cardId,
  isFeedbackModalOpen,
  onClickModal,
  cardName,
  profileId,
  rivalId,
}: KlueCardFeedbackModalProps) {
  const { t } = useTranslation();
  const [feedbackContent, setFeedbackContent] = useState('');
  const [createCardComment, { isLoading, isSuccess, isError, reset }] =
    useCreateCardCommentMutation();

  const handleShareFeedback = async () => {
    if (feedbackContent) {
      await createCardComment({ cardId, body: feedbackContent });
    }
  };

  const closeFeedback = useCallback(() => {
    setFeedbackContent('');
    onClickModal(false);
    reset();
  }, [onClickModal, reset]);

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    if (isSuccess) {
      setFeedbackContent('');
      intervalId = setInterval(() => {
        closeFeedback();
      }, FEEDBACK_INTERVAL);
    }

    return () => clearInterval(intervalId);
  }, [closeFeedback, isSuccess, onClickModal, reset]);

  return (
    <>
      {isFeedbackModalOpen ? (
        <Modal showCloseButton={false} onClose={closeFeedback}>
          <Modal.Header background={tokens.color.primary.main}>
            <Box direction="row" gap="small" align="center">
              <Icon.ShareFeedback />
              <Heading
                size="medium"
                margin="none"
                level="2"
                color={tokens.color.primary.contrastmain}
              >
                {isSuccess
                  ? t('Common:shareFeedback.success.header')
                  : t('Common:shareFeedback.header')}
              </Heading>
            </Box>
            <Button
              variant="plain"
              onClick={closeFeedback}
              icon={<Icon.Close color={tokens.color.primary.contrastmain} />}
            />
          </Modal.Header>
          <Modal.Content>
            {isSuccess ? (
              <Box align="center" margin="xxlarge">
                <Image
                  width={theme.global?.size?.small}
                  src={SUBMIT_INTEL_IMG}
                />
                <Heading
                  level={4}
                  size="small"
                  margin={{ top: 'large' }}
                  color={tokens.color.primary.dark}
                >
                  {t('Common:shareFeedback.success.title')}
                </Heading>
              </Box>
            ) : (
              <Box>
                <Text
                  size="medium"
                  color={tokens.color.neutral.black.main}
                  margin={{ bottom: 'large' }}
                >
                  {t('Common:shareFeedback.title')}
                </Text>
                {profileId && cardName && (
                  <Breadcrumb
                    profileId={profileId}
                    cardName={cardName}
                    rivalId={rivalId}
                  />
                )}
                <Box
                  margin={{ vertical: 'large' }}
                  fill="horizontal"
                  gap="medium"
                >
                  {isError && (
                    <Banner
                      message={t('Common:fatalErrorFeedback.title')}
                      variant="inline"
                      status="error"
                      show
                      size="small"
                      fontWeight="normal"
                      showIcon
                    />
                  )}
                  <TextArea
                    style={{
                      fontWeight: 'lighter',
                      minHeight: '200px',
                    }}
                    size="medium"
                    placeholder={t('Common:shareFeedback.placeholder')}
                    onChange={(e) => setFeedbackContent(e.target.value)}
                    resize={false}
                    data-test-id={
                      TEST_IDS.klueCard.klueCardFeedbackModal.textarea
                    }
                  />
                </Box>
                <Box align="center" justify="end" direction="row" gap="small">
                  <Button
                    variant="plain"
                    label={t('Common:actions.cancel')}
                    onClick={closeFeedback}
                    data-tracking-id={
                      TRACKING_IDS.global.card.shareFeedback.cancelModal
                    }
                    data-test-id={
                      TEST_IDS.klueCard.klueCardFeedbackModal.cancel
                    }
                  />
                  <Button
                    variant="highlight-form"
                    onClick={handleShareFeedback}
                    disabled={!feedbackContent}
                    loading={isLoading}
                    label={t('Card:sentiment.button.shareFeedback')}
                    data-tracking-id={
                      TRACKING_IDS.global.card.shareFeedback.sendModal
                    }
                    data-test-id={TEST_IDS.klueCard.klueCardFeedbackModal.send}
                  />
                </Box>
              </Box>
            )}
          </Modal.Content>
        </Modal>
      ) : null}
    </>
  );
}

export default KlueCardFeedbackModal;
