import { getUnixTime } from 'date-fns';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useAuth } from 'contexts/auth';
import { useEmbedded } from 'contexts/ui/embedded-context';
import { Env, getEnv } from 'lib/utils';

import loadIntercom from './loadIntercom';

import type { CompanyType, UserType } from 'api/api.types';

type IntercomSettings = {
  app_id: string;
  name: string;
  email: string;
  user_id: number;
  user_hash: string;
  created_at: number;
  last_seen_at: number;
  is_curator: boolean;
  company: {
    name: string;
    id: number;
    created_at: number;
  };
  hide_default_launcher: boolean;
};
type IntercomType = (event: string, settings?: IntercomSettings) => void;
type IntercomInstalled = {
  Intercom?: IntercomType;
  intercomSettings?: IntercomSettings;
};

function nameForEnvironment(name: string) {
  switch (getEnv()) {
    case Env.Development:
      return name + ' [TEST]';
    case Env.Staging:
      return name + ' [STAGING]';
    default:
      return name;
  }
}

function IntercomEnabled({
  appId,
  intercom,
  user,
  company,
  isCurator,
  isAdmin,
}: {
  appId: string;
  intercom: IntercomType;
  user: UserType;
  company: CompanyType;
  isCurator: boolean;
  isAdmin: boolean;
}) {
  const {
    name,
    email,
    id: userId,
    apiKeys: { intercomUserHash },
    createdAt,
    lastSeenAt,
    emailDigest,
  } = user;
  const {
    name: companyName,
    id: companyId,
    createdAt: companyCreatedAt,
  } = company;
  const { pathname } = useLocation();

  useEffect(() => {
    const settings = {
      app_id: appId,
      name: nameForEnvironment(name),
      email,
      email_digest: emailDigest,
      user_id: userId,
      user_hash: intercomUserHash,
      created_at: getUnixTime(new Date(createdAt)),
      last_seen_at: getUnixTime(new Date(lastSeenAt)),
      is_curator: isCurator,
      is_admin: isAdmin,
      company: {
        name: companyName,
        id: companyId,
        created_at: getUnixTime(new Date(companyCreatedAt)),
      },
      hide_default_launcher: true,
    };
    (window as IntercomInstalled).intercomSettings = settings;
    intercom('update', settings);
  }, [
    pathname,
    appId,
    intercom,
    name,
    email,
    emailDigest,
    userId,
    intercomUserHash,
    createdAt,
    lastSeenAt,
    isCurator,
    isAdmin,
    companyName,
    companyId,
    companyCreatedAt,
  ]);

  return null;
}

export default function Intercom({ appId }: { appId: string }) {
  const { user, company, isCurator, isAdmin } = useAuth();
  const [isLoaded, setIsLoaded] = useState(false);
  const isEmbedded = useEmbedded();
  useEffect(() => {
    if (!isEmbedded && !isLoaded && user && !user.isImpersonating && company) {
      loadIntercom(appId);
      setIsLoaded(true);
    }
  }, [appId, isLoaded, user, company, isEmbedded]);
  const intercom = (window as IntercomInstalled).Intercom;
  if (isLoaded && intercom && user && company) {
    return (
      <IntercomEnabled
        appId={appId}
        intercom={intercom}
        user={user}
        company={company}
        isCurator={isCurator}
        isAdmin={isAdmin}
      />
    );
  }
  return null;
}
